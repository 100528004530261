import React from 'react'

import solarProducts from './constants/products.json'
import ProductImageCard from '../../components/molecules/Card/ProductImageCard'
import TextBase from '../../components/atoms/Typography/TextBase'

const SolarProducts = () => {
  return (
    <div className="container mx-auto px-4 md:px-2 py-5">
      <TextBase fontMM>
        မီးပျက်လည်း အလုပ်မပျက်စေဖို့ မီးအမြဲရအောင် စက်ရုံ၊ အလုပ်ရုံတိုင်း အတွက် ၊ ရေရရှိရေးအတွက်လည်း စိတ်ပူစရာမလိုအောင်
        Solar Equipment တွေအကြောင်း သေချာနားလည်ပြီး ကိုယ်တိုင်အကျိုးရှိစွာ အသုံးပြုနိုင်စေရန် AME Company က
        သေချာဆွေးနွေးလမ်းညွှန်ပေးပြီး Technical Sharing လုပ်ပေးနေပါတယ််။ တောင်သူဉီးကြီးများလည်း စိုက်ခင်းများအတွက် Solar
        စနစ်ဖြင့်ရေတင်နိုင်ပြီး လူကြီးမင်းတို့အတွက် ပိုမိုကောင်းမွန်သော အကျိုးကျေးဇူးများ ရရှိနိုင်ပါတယ်။
      </TextBase>

      <div className="grid grid-cols-12 gap-6 py-6">
        {solarProducts.map((each) => (
          <div className={`md:col-span-4 2xl:col-span-3 col-span-6`} key={each.id}>
            <ProductImageCard imageUrl={each.url} imageSize="80%" />
            <p className="text-center mt-3 text-sm md:text-base font-medium">{each.name}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SolarProducts
