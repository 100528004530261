import React, { useCallback, useRef } from 'react'
import CloseIcon from '../../../../atoms/Icons/CloseIcon'
import { useOnClickOutside } from 'usehooks-ts'
import { Menu, MenuLabel, MenuPath } from '../../../../../constants/menus'
import MenuItem from './MenuItem'

interface MobileMenuSliderProps {
  isOpen: boolean
  toggleMenu(): void
}

const MobileMenuSlider: React.FC<MobileMenuSliderProps> = ({ isOpen, toggleMenu }) => {
  /** ------------------------------ Hooks ------------------------------ */
  const ref = useRef(null)

  /** ------------------------------ Logics ------------------------------ */
  const handleClickOutside = useCallback(() => {
    if (isOpen) toggleMenu()
  }, [isOpen, toggleMenu])

  useOnClickOutside(ref, handleClickOutside)

  /** ------------------------------ Component UI Renderer ------------------------------ */
  return (
    <div
      ref={ref}
      className={`fixed top-0 left-0 h-screen w-64 bg-white  
                   transform transition-transform duration-300 shadow py-6
                 
                   ${isOpen ? 'translate-x-0 z-999' : '-translate-x-full z-999'}`}
    >
      {/* Close Button */}
      <div className="flex justify-center">
        <CloseIcon onClick={toggleMenu} />
      </div>

      {/* Main Menu Content */}
      <div className="flex flex-col gap-5 items-center py-3 px-10 mt-7">
        {Object.values(Menu).map((each) => (
          <MenuItem name={MenuLabel[each]} path={MenuPath[each]} key={each} onClick={handleClickOutside} />
        ))}
      </div>
    </div>
  )
}

export default MobileMenuSlider
