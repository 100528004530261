import React, { HtmlHTMLAttributes } from 'react'

interface BulletPatternIconProps extends HtmlHTMLAttributes<HTMLImageElement> {}

const BulletPatternIcon: React.FC<BulletPatternIconProps> = ({ className, ...props }) => {
  return (
    <img
      {...props}
      className={`${className ? className : ''} inline-block`}
      src="/assets/icons/bullet-pattern-icon.svg"
      alt="Bullet pattern icon"
    />
  )
}

export default BulletPatternIcon
