import React, { HtmlHTMLAttributes } from 'react'

interface TextBaseProps extends HtmlHTMLAttributes<HTMLParagraphElement> {
  fontMM?: boolean
}

const TextBase: React.FC<TextBaseProps> = ({ fontMM, className, ...props }) => {
  return (
    <p className={`${className ? className : ''} ${fontMM ? '!leading-10' : ''} text-sm md:text-base font-normal`}>
      {props.children}
    </p>
  )
}

export default TextBase
