enum Menu {
  Home = 'HOME',
  SubstationProducts = 'SUBSTATION_PRODUCTS',
  IoTProducts = 'IOT_PRODUCTS',
  SolarProducts = 'SOLAR_PRODUCTS',
  Services = 'SERVICES',
  About = 'ABOUT',
  Contact = 'CONTACT'
}

const MenuLabel: Record<Menu, string> = {
  HOME: 'Home',
  SUBSTATION_PRODUCTS: 'Substation Products',
  IOT_PRODUCTS: 'IoT Products',
  SOLAR_PRODUCTS: 'Solar Products',
  SERVICES: 'Services  ',
  ABOUT: 'About',
  CONTACT: 'Contact'
}

const MenuPath: Record<Menu, string> = {
  HOME: '/',
  SUBSTATION_PRODUCTS: '/substation-products',
  IOT_PRODUCTS: '/iot-products',
  SOLAR_PRODUCTS: '/solar-products',
  SERVICES: '/services',
  ABOUT: '/about',
  CONTACT: '/contact'
}

export { MenuPath, MenuLabel, Menu }
