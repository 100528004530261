import { createBrowserRouter } from 'react-router-dom'
import ErrorNotFound from '../components/templates/errors/ErrorNotFound'
import MainLayout from '../components/templates/layouts/MainLayout'
import { MenuPath } from '../constants/menus'
import HomePage from '../pages/home'
import SubstationProductsPage from '../pages/substationProducts'
import IotProductsPage from '../pages/iotProducts'
import SolarProductsPage from '../pages/solarProducts'
import OurServicesPage from '../pages/ourServices'
import AboutPage from '../pages/about'
import ContactPage from '../pages/contact'

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: [
      {
        path: MenuPath.HOME,
        element: <HomePage />
      },
      {
        path: MenuPath.SUBSTATION_PRODUCTS,
        element: <SubstationProductsPage />
      },
      {
        path: MenuPath.IOT_PRODUCTS,
        element: <IotProductsPage />
      },
      {
        path: MenuPath.SOLAR_PRODUCTS,
        element: <SolarProductsPage />
      },
      {
        path: MenuPath.SERVICES,
        element: <OurServicesPage />
      },
      {
        path: MenuPath.ABOUT,
        element: <AboutPage />
      },
      {
        path: MenuPath.CONTACT,
        element: <ContactPage />
      },
      {
        path: '*',
        element: <ErrorNotFound />,
        errorElement: <ErrorNotFound />
      }
    ]
  }
])

export default router
