import React from 'react'
import { useToggle } from 'usehooks-ts'
import BurgerMenuIcon from '../../../../atoms/Icons/BurgerMenuIcon'
import { Navbar } from 'flowbite-react'
import Logo from '../../../../atoms/Logo'
import MobileMenuSlider from './MobileMenuSlider'

const MobileNav = () => {
  const [isOpen, toggleMenu] = useToggle(false)

  return (
    <div className="md:hidden w-full z-50 relative mt-3">
      {/* Toggle Button */}
      <button className="absolute top-[50%] -translate-y-[50%] left-2 md:hidden" onClick={toggleMenu}>
        <BurgerMenuIcon color="primary" />
      </button>

      {/* Brand Logo */}
      <div className="flex justify-center w-full">
        <Navbar.Brand as={'a'} href="/">
          <Logo className="!h-20" />
        </Navbar.Brand>
      </div>

      {/* Menu slider */}
      <MobileMenuSlider isOpen={isOpen} toggleMenu={toggleMenu} />
    </div>
  )
}

export default MobileNav
