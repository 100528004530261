import React from 'react'
import PhoneIcon from '../../atoms/Icons/PhoneIcon'
import TitleBase from '../../atoms/Typography/TitleBase'

interface PhoneNumberInfoProps {
  className?: string
}

const PhoneNumberInfo: React.FC<PhoneNumberInfoProps> = ({ className }) => {
  return (
    <div className="flex items-center gap-2">
      <PhoneIcon />
      <TitleBase className={`${className ? className : ''}`}>{process.env.REACT_APP_APP_BRAND_PHONE}</TitleBase>
    </div>
  )
}

export default PhoneNumberInfo
