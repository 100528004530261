import React from 'react'

const DesktopBannerInfo = () => {
  return (
    <div className="hidden container mx-auto lg:grid grid-cols-12 gap-6 py-12 px-16 pb-5">
      <div className="col-span-6 lg:col-span-4 z-10">
        <img src="/assets/info-images/about-image-1-1.png" alt="About company - 1" className="z-10" />
      </div>

      <div className="col-span-6 lg:col-span-4 ">
        <img src="/assets/info-images/about-image-1-2.png" alt="About company - 2" className="-ml-24" />
      </div>

      <div className="col-span-12 lg:col-span-4 -ml-5 flex items-center justify-center">
        <div className="flex gap-3">
          <div>
            <p className="partner-year">2016</p>
            <img src="/assets/info-images/partner-1.jpg" alt="Partner company - 1" className="partner-img" />
          </div>

          <div>
            <p className="partner-year">2019</p>
            <img src="/assets/info-images/partner-2.jpg" alt="Partner company - 1" className="partner-img" />
          </div>

          <div>
            <p className="partner-year">2020</p>
            <img src="/assets/info-images/partner-3.jpg" alt="Partner company - 1" className="partner-img" />
          </div>
        </div>
      </div>

      <div className="col-span-12">
        <p className="partner-companies mt-3">
          Partner companies : 1 Bit Manager Co., Ltd. <span className="text-warning-light">|</span> &nbsp;Earth Movers
          Company <span className="text-warning-light">|</span> Everything Electronic Company
        </p>
      </div>
    </div>
  )
}

export default DesktopBannerInfo
