import React from 'react'
import DesktopBannerInfo from './components/DesktopBannerInfo'
import MobileBannerInfo from './components/MobileBannerInfo'
import TextBase from '../../components/atoms/Typography/TextBase'
import BulletPatternIcon from '../../components/atoms/Icons/BulletPatternIcon'

const About = () => {
  return (
    <>
      <div className="bg-dark-blue">
        {/* Tablet and Desktop view - hide in mobile */}
        <DesktopBannerInfo />

        {/* Mobile view - hide in Tablet and Desktop */}
        <MobileBannerInfo />
      </div>

      <div className="container mx-auto px-4 md:px-2 py-6">
        <TextBase fontMM>
          ကျွန်တော်တို့၏ Active Myanmar Electric Engineering ကုမ္ပဏီသည် ၂၀၁၉ ခုနှစ် ဧပြီလ (၂၅) ရက်နေ့တွင်
          စတင်ဖွဲ့စည်းတည်ထောင်ခဲ့ပါသည်။ ဓာတ်အားခွဲရုံများနှင့်လျှပ်စစ်ဆိုင်ရာလုပ်ငန်းများအတွက်ဆက်စပ်သော
          လျှပ်စစ်ပစ္စည်းကိရိယာ အမျိုးမျိုးနှင့် ဆိုလာပစ္စည်းအမျိုးမျိုးတို့ကို ဖြန့်ဖြူးရောင်းချတပ်ဆင်ပေးခြင်း၊
          ဗို့အား၊ဝန်အား အမျိုးမျိုးရှိသည့် ဓာတ်အားခွဲရုံများ တည်ဆောက်တပ်ဆင်ခြင်း နှင့်ပြုပြင် ထိန်းသိမ်းပေးခြင်း၊
          66/33/11/0.4kVဓာတ်အားလိုင်းများကို တည်ဆောက်တပ်ဆင်ခြင်းနှင့် ပြုပြင်ထိန်းသိမ်းပေးခြင်း၊ ဓာတ်အားခွဲရုံများတွင်
          တပ်ဆင်ထားသော လျှပ်စစ်ပစ္စည်းကိရိယာ အမျိုးမျိုးတို့ကို စစ်ဆေးပေးခြင်း၊ ပြုပြင်မွမ်းမံလဲလှယ်ပေးခြင်း၊ အိမ်ရာ
          အဆောက်အဦး၊ စက်ရုံ၊ အလုပ်ရုံတို့ကို လျှပ်စစ်သွယ်တန်း တပ်ဆင်ပေးခြင်း၊ လျှပ်စစ်ဆိုင်ရာ
          လုပ်ငန်းအမျိုးမျိုးတို့အတွက် အကြံပေးခြင်းနှင့်ဝန်ဆောင်မှု အမျိုးမျိုးတို့ကို ဆောင်ရွက်ပေးခြင်း၊
          Panelအမျိုးမျိုး၊ လျှပ်စစ်ပစ္စည်းကိရိယာအမျိုးမျိုးတို့ကို ထုတ်လုပ်ခြင်းနှင့် တင်သွင်းဖြန့်ဖြူးခြင်း စသည့်
          လျှပ်စစ်အင်ဂျင်နီယာလုပ်ငန်းကိုအခြေခံသောဝန်ဆောင်မှုနှင့် ထုတ်လုပ်ခြင်းလုပ်ငန်းများကို ဆောင်ရွက်လျက်ရှိပါသည်။{' '}
        </TextBase>

        <br />

        <TextBase fontMM>
          AME သည် Substation Monitory Device ကို ထုတ်လုပ်နိုင်ခဲ့ပြီး အဆိုပါပစ္စည်း၏ စွမ်းဆောင်မှုသည် ၎င်းကို
          ဓာတ်အားခွဲရုံတွင် တပ်ဆင်ထားခြင်းဖြင့် မိမိ၏ ကိုယ်ပိုင် Mobile Smart Phone, Tablet စသည်ဖြင့်သော်လည်းကောင်း၊
          ထိန်းချုပ်ခန်းမှသော်လည်းကောင်း၊ ကွန်ပျူတာမှတဆင့် လည်းကောင်း စောင့်ကြပ်ကြည့်ရှုနိုင်ပြီး လိုအပ်သောစီမံမှုနှင့်
          လုပ်ဆောင်မှုအမျိုးမျိုးကိုစွမ်းဆောင် နိုင်ပါသည်။
        </TextBase>

        <br />

        <TextBase fontMM>
          ကုမ္ပဏီ၏ ဆောင်ပုဒ်မှာ{' '}
          <b>“Our Life is service for you” “ကျွန်ုပ်တို့၏ နေ့စဉ်လုပ်ရှားမှု သည် သင်တို့အတွက် ဝန်ဆောင်မှု”</b> ဖြစ်ပြီး
          ကျွန်ုပ်တို့၏ နေ့စဉ်လှုပ်ရှားမှုတွေသည် အလုပ်ရှင်များ အတွက် ဝန်ဆောင်မှုပေးခြင်းဖြင့် ၎င်းတို့၏
          လုပ်ငန်းဆောင်တာများ အဆင်ပြေမှု၊ အောင်မြင်မှုကို ထောက်ကူမှု ပေးခြင်း ကို ဦးတည်သော ရည်ရွယ်ချက်ဖြင့်
          အလေးထားဆောင်ရွက်မည် ဖြစ်ပါသည်။
        </TextBase>

        <div className="grid grid-cols-12 gap-6 lg:gap-8 xl:gap-12 py-8 md:px-3 lg:px-8">
          {/* Mission */}
          <div className="lg:col-span-4 col-span-12">
            <div className="flex gap-3 items-center mb-2  ">
              <BulletPatternIcon />
              <span className="text-base md:text-lg font-medium">Our Mission</span>
            </div>

            <TextBase fontMM>
              တိကျစွာ ဒီဇိုင်းထုတ်ထားသော၊ အကြမ်းခံပြီး အရည်အသွေးအကောင်းဆုံးဖြစ်သည့် တန်ဖိုးရှိသော ထုတ်ကုန်များကို
              ဖြည့်ဆည်းပေးရန်။ ဖောက်သည်များ၏ စိတ်ကျေနပ်မှုကို ဦးစားပေးပြီး ကျွန်ုပ်တို့၏ ဖောက်သည်များအား ရိုးသားမှုနှင့်
              ဂုဏ်သိက္ခာရှိစွာ ဝန်ဆောင်မှုပေးခြင်းမှ ကျွန်ုပ်တို့၏လုပ်ငန်းကို ကြီးထွားစေရန် ကြိုးပမ်းပါမည်။
            </TextBase>
          </div>

          {/* Vision */}
          <div className="lg:col-span-4 col-span-12">
            <div className="flex gap-3 items-center mb-2  ">
              <BulletPatternIcon />
              <span className="text-base md:text-lg font-medium">Our Vision</span>
            </div>

            <TextBase fontMM>
              လာမည့်ငါးနှစ်အတွင်း မြန်မာနိုင်ငံ၏ ထိပ်တန်းလျှပ်စစ်ပစ္စည်းထုတ်လုပ်သူ ၁၀ ဦးထဲမှ တစ်ဦးဖြစ်လာရန်။
            </TextBase>
          </div>

          {/* Value */}
          <div className="lg:col-span-4 col-span-12">
            <div className="flex gap-3 items-center mb-2  ">
              <BulletPatternIcon />
              <span className="text-base md:text-lg font-medium">Our Value</span>
            </div>

            <TextBase fontMM>
              ကျွန်ုပ်တို့သည် သက်ဆိုင်သူအားလုံးအတွက် ကျန်းမာရေး၊ ဘေးကင်းရေးနှင့် ပတ်ဝန်းကျင်ဆိုင်ရာ အစီအမံများကို
              အားပေးနေစဉ်တွင် ကျွန်ုပ်တို့သည် တာဝန်ယူမှု၊ တာဝန်ခံမှု၊ လေးစားမှုနှင့် ထိရောက်မှုရှိသော
              ထုတ်လုပ်သူများဖြစ်သည်။
            </TextBase>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
