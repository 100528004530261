import React from 'react'

interface SocialProofItemProps {
  label: string
  value: number | string
  suffix?: string
  className?: string
}

const SocialProofItem: React.FC<SocialProofItemProps> = ({ label, value, suffix, className }) => {
  return (
    <div className={`${className ? className : ''}`}>
      <p className="text-2xl md:text-3xl lg:text-4xl font-medium">
        {value}
        {suffix && <span className="text-primary">{suffix}</span>}
      </p>

      <p className="text-sm md:text-base lg:text-lg font-normal">{label}</p>
    </div>
  )
}

export default SocialProofItem
