import React, { HtmlHTMLAttributes } from 'react'

interface CheckedIconProps extends HtmlHTMLAttributes<HTMLImageElement> {}

const CheckedIcon: React.FC<CheckedIconProps> = ({ className, ...props }) => {
  return (
    <img
      src="/assets/icons/checked-icon.svg"
      alt="Checked Icon"
      className={`${className ? className : ''} inline-block`}
    />
  )
}

export default CheckedIcon
