import React from 'react'
import MenuItem from './MenuItem'
import { Menu, MenuLabel, MenuPath } from '../../../../../constants/menus'
import { Navbar } from 'flowbite-react'
import Divider from '../../../../atoms/Divider'
import Logo from '../../../../atoms/Logo'
import LocationInfo from '../../../../molecules/InfoItems/LocationInfo'
import PhoneNumberInfo from '../../../../molecules/InfoItems/PhoneNumberInfo'
import WorkingHoursInfo from '../../../../molecules/InfoItems/WorkingHoursInfo'

interface DesktopNavBarProps {}

const DesktopNavBar: React.FC<DesktopNavBarProps> = () => {
  return (
    <div className="hidden md:block w-full">
      <div className="flex justify-between items-center w-full">
        {/* Brand Logo */}
        <Navbar.Brand as={'a'} href="/">
          <Logo />
        </Navbar.Brand>

        {/* Brand Info Banner */}
        <div className="flex items-center gap-5 ">
          <LocationInfo />

          <WorkingHoursInfo />

          <PhoneNumberInfo />
        </div>
      </div>

      <Divider className="mt-3" />

      {/* Nav Menus */}
      <div className="flex justify-between py-3 px-10">
        {Object.values(Menu).map((each) => (
          <MenuItem name={MenuLabel[each]} path={MenuPath[each]} key={each} />
        ))}
      </div>
    </div>
  )
}

export default DesktopNavBar
