import React from 'react'
import Header from '../Header'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../Footer'
import Divider from '../../../atoms/Divider'
import { MenuPath } from '../../../../constants/menus'

const MainLayout: React.FC = () => {
  /** ------------------------------ Hooks ------------------------------ */
  const location = useLocation()

  return (
    <div className="h-full">
      <Header />

      {location.pathname !== MenuPath.HOME && <Divider className="mt-3 md:mt-0" />}

      <Outlet />

      <Footer />
    </div>
  )
}

export default MainLayout
