import React from 'react'
import ClockIcon from '../../atoms/Icons/ClockIcon'
import TitleBase from '../../atoms/Typography/TitleBase'

interface WorkingHoursInfoProps {
  className?: string
}

const WorkingHoursInfo: React.FC<WorkingHoursInfoProps> = ({ className }) => {
  return (
    <div className="flex items-center gap-2">
      <ClockIcon />
      <TitleBase className={`${className ? className : ''}`}>Mon - Sat 8:00 AM - 5:00 PM</TitleBase>
    </div>
  )
}

export default WorkingHoursInfo
