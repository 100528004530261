import React from 'react'
import TitleXxl from '../../../components/atoms/Typography/TitleXxl'
import TitleBase from '../../../components/atoms/Typography/TitleBase'
import { Button } from 'flowbite-react'
import { Link } from 'react-router-dom'
import { MenuPath } from '../../../constants/menus'
import SocialProofItem from '../../../components/molecules/SocialProofItem'

const OurServices = () => {
  return (
    <section className="">
      <div
        className="py-7 flex flex-col justify-center items-center px-5"
        style={{ backgroundImage: "url('assets/background-images/our_service_bg.jpg')" }}
      >
        <TitleXxl className="text-center mb-3 text-white">Our Services</TitleXxl>

        <div className="flex items-center gap-5 flex-col md:flex-row">
          <TitleBase className="!font-normal text-center text-white">
            ကျွန်တော်တို့ ၏ ပြည့်စုံသောဝန်ဆောင်မှုများဖြင့် သင့်လုပ်ငန်းလိုအပ်ချက်များကို ဖြည့်ဆည်းပါ။
          </TitleBase>

          <Link to={MenuPath.SERVICES} className="relative">
            <Button color="primary" className="z-10 px-6 hover:bg-opacity-100 hover:-translate-x-1 hover:translate-y-1">
              More Details
            </Button>
            <div className="w-full h-full bg-warning-light absolute top-1 -left-1 -z-0"></div>
          </Link>
        </div>
      </div>

      <div className="hidden md:flex justify-center items-center py-8 lg:gap-20 md:gap-10">
        <SocialProofItem label="Years of Experience" value={5} suffix="+" />
        <SocialProofItem label="Projects Completed" value={50} suffix="+" />
        <SocialProofItem label="Customer Satisfaction" value={100} suffix="%" />
        <SocialProofItem label="Professional Staff" value={30} suffix="+" />
      </div>

      <div className="grid grid-cols-12 gap-6 px-8 md:hidden py-8">
        <div className="col-span-6">
          <SocialProofItem label="Years of Experience" value={5} suffix="+" />
        </div>

        <div className="col-span-6">
          <SocialProofItem label="Projects Completed" value={50} suffix="+" />
        </div>

        <div className="col-span-6">
          <SocialProofItem label="Customer Satisfaction" value={100} suffix="%" />
        </div>

        <div className="col-span-6">
          <SocialProofItem label="Professional Staff" value={30} suffix="+" />
        </div>
      </div>
    </section>
  )
}

export default OurServices
