import React from 'react'
import Banner from './components/Banner'
import OurProducts from './components/OurProducts'
import OurServices from './components/OurServices'

const Home: React.FC = () => {
  return (
    <div className="mt-3 md:mt-0">
      <Banner />

      <OurProducts />

      <OurServices />
    </div>
  )
}

export default Home
