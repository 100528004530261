import React from 'react'

const MobileBannerInfo = () => {
  return (
    <div className="pb-5 lg:hidden">
      <div className="z-10 flex overflow-hidden">
        <img
          src="/assets/info-images/about-image-mobile-1.png"
          alt="About company - 1"
          className="z-10 w-[300px] md:w-[70%]"
        />
        <img
          src="/assets/info-images/about-image-mobile-2.png"
          alt="About company - 2"
          className="-ml-24 md:-ml-32 w-[300px] md:w-[50%]"
        />
      </div>

      <div className="flex items-center justify-center mt-3 md:mt-6 mb-6 md:mb-8">
        <div className="flex gap-3">
          <div>
            <p className="partner-year">2016</p>
            <img src="/assets/info-images/partner-1.jpg" alt="Partner company - 1" className="partner-img" />
          </div>

          <div>
            <p className="partner-year">2019</p>
            <img src="/assets/info-images/partner-2.jpg" alt="Partner company - 1" className="partner-img" />
          </div>

          <div>
            <p className="partner-year">2020</p>
            <img src="/assets/info-images/partner-3.jpg" alt="Partner company - 1" className="partner-img" />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6 mb-3">
        <p className="partner-companies ">Partner companies :</p>
        <p className="partner-companies">1 Bit Manager Co., Ltd.</p>
        <p className="partner-companies">Earth Movers Company</p>
        <p className="partner-companies">Everything Electronic Company</p>
      </div>
    </div>
  )
}

export default MobileBannerInfo
