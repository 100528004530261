import React from 'react'
import TextBase from '../../components/atoms/Typography/TextBase'

const IotProducts = () => {
  return (
    <div className="container mx-auto px-4 md:px-2 py-8">
      <TextBase fontMM>
        ကျွန်တော်တို့ Active Myanmar Electric Engineering ကုမ္ပဏီမှ Internet အသုံးပြုပြီး လူကြီးမင်းတို့၏
        လျှပ်စစ်ဆိုင်ရာ လုပ်ငန်းများကို လွယ်ကူစွာလုပ်ဆောင်နိုင်သော အဆင့်အတန်း မြင့်မားသည့် ခွဲရုံသုံး IoT Product ကို
        ရရှိနိုင်ပါသည်။ ကျွန်တော်တို့၏ IoT Product ကို လူကြီးမင်းတို့၏ စက်ရုံအလုပ်ရုံရှိခွဲရုံများတွင်
        လျှပ်စစ်ပိုင်းဆိုင်ရာ ဖြစ်ပေါ်လာသော အခက်အခဲများကို လူကြီးမင်းတို့၏ တန်ဖိုးရှိသော လျှပ်စစ်ပစ္စည်းကိရိယာများ၊
        အချိန်များအတွက် ဖုန်း၊ ကွန်ပျူတာစသည့် Smart Device တို့ဖြင့် အလွယ်တကူ ဖြေရှင်းနိုင်စေရန်၊ လျှပ်စစ်ပိုင်းဆိုင်ရာ
        လုပ်ငန်းဆောင်တာများကို လုပ်ဆောင်နိုင်ရန်၊ အသုံးပြုတပ်ဆင်ထားသော လျှပ်စစ်ဆိုင်ရာပစ္စည်းများလျှပ်စစ်ကြောင့်
        ပျက်စီးဆုံးရှုံးမှုမရှိစေရန်ရည်ရွယ်ပြီး ထုတ်လုပ်ထားခြင်း ဖြစ်ပါသည်။ ထို့ကြောင့် AME မှ IoT Productကို
        အသုံးပြုပြီး လူကြီးမင်းတို့အတွက် ပိုမိုကောင်းမွန်သော အကျိုးကျေးဇူးများကို ရယူနိုင်ပါသည်။
      </TextBase>

      <div className="grid grid-cols-8 gap-6 py-6 pb-8">
        <div className={`md:col-start-2 md:col-span-6  col-span-8`}>
          <img src="/assets/info-images/iot-page-image-1.jpg" alt="IOT description - 1" />
        </div>

        <div className={`md:col-start-2 md:col-span-6  col-span-8`}>
          <img src="/assets/info-images/iot-page-image-2.jpg" alt="IOT description - 2" />
        </div>
      </div>
    </div>
  )
}

export default IotProducts
