import React from 'react'
import LocationIcon from '../../atoms/Icons/LocationIcon'
import TitleBase from '../../atoms/Typography/TitleBase'

interface LocationInfoProps {
  className?: string
}

const LocationInfo: React.FC<LocationInfoProps> = ({ className }) => {
  return (
    <div className="flex items-center gap-2">
      <LocationIcon />
      <TitleBase className={`${className ? className : ''}`}>139 C, 49 st x 50st, Pyigyitagon Tsp, Mandalay</TitleBase>
    </div>
  )
}

export default LocationInfo
