import React from 'react'

import ourServices from './constants/services.json'
import CheckedIcon from '../../components/atoms/Icons/CheckedIcon'
import TextBase from '../../components/atoms/Typography/TextBase'

const OurServices = () => {
  return (
    <div className="container mx-auto px-4 md:px-2 py-5">
      <TextBase fontMM>
        Active Myanmar Electric Engineering ကုမ္ပဏီ၏ Service Team သည် အောက်ပါတို့ကို ဆောင်ရွက်လျက်ရှိပါသည်
      </TextBase>

      <div className="flex flex-col gap-3 mt-3 mb-5">
        {ourServices.map((each, index) => (
          <TextBase fontMM>
            <CheckedIcon className="mr-3" />
            {each.name}{' '}
            {ourServices.length === index + 1 && 'စသည့် ဝန်ဆောင်မှု အမျိုးမျိုးတို့ကို ဆောင်ရွက်ပေးလျက်ရှိပါသည်။'}
          </TextBase>
        ))}
      </div>

      <div className="grid grid-cols-12 gap-6 mb-12 pt-5">
        <div className={` md:col-span-4 col-span-12`}>
          <img src="/assets/info-images/our-service-image-1.jpg" alt="Our service info - 1" />
        </div>
        <div className={` md:col-span-4 col-span-12`}>
          <img src="/assets/info-images/our-service-image-2.jpg" alt="Our service info - 1" />
        </div>
      </div>
    </div>
  )
}

export default OurServices
