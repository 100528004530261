import React from 'react'
import IconSquareBg from './IconSquareBg'

interface ClockIconProps {}

const ClockIcon: React.FC<ClockIconProps> = () => {
  return (
    <IconSquareBg>
      <img src="/assets/icons/clock.svg" alt="working hour icon" />
    </IconSquareBg>
  )
}

export default ClockIcon
