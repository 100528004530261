import React from 'react'
import useBreakPoint from '../../../hooks/useBreakPoint'
import { BRAND_COMPANY_NAME } from '../../../constants/brandInfo'

interface BannerProps {}

const Banner: React.FC<BannerProps> = () => {
  const { xs, sm, xl } = useBreakPoint()

  return (
    <div className="flex overflow-hidden ">
      <div className="bg-primary w-full relative">
        <div
          className={`${xl ? 'w-[400px] h-[168px]' : sm ? 'w-[300px] h-[126px]' : 'w-[220px] h-[92px]'} 
            absolute top-[50%] -translate-y-[50%] right-0 ${
              xl ? 'translate-x-[50%]' : sm ? 'translate-x-[60%]' : 'translate-x-[75%]'
            }  
          `}
        >
          <div className="bg-white p-3 flex flex-col justify-center items-center w-full h-full relative">
            <h1 className={`${xl ? 'text-3xl' : sm ? 'text-xl' : 'text-base'} font-medium`}>
              “Our life is service for you”
            </h1>
            <h2 className={`${xl ? 'text-xl' : sm ? 'text-base' : 'text-sm'} font-light`}>{BRAND_COMPANY_NAME}</h2>

            <div className="absolute w-full h-full bg-warning-light -z-10 top-2.5 -left-2.5" />
          </div>
        </div>
      </div>

      <img
        src={xs ? '/assets/banners/home_banner_2x.jpg' : '/assets/banners/home_banner_mobile_2x.jpg'}
        alt="Home page banner"
        className="w-[80%]"
      />
    </div>
  )
}

export default Banner
