import { Navbar } from 'flowbite-react'
import DesktopMenuBar from './components/DesktopNavBar'
import MobileNav from './components/MobileNav'
import React from 'react'

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  return (
    <div className="container mx-auto">
      <Navbar rounded className="px-4 md:px-2 pb-0">
        <MobileNav />
        <DesktopMenuBar />
      </Navbar>
    </div>
  )
}

export default Header
