import React from 'react'

interface ProductImageCardProps {
  imageUrl: string
  className?: string
  imageSize?: string
}

const ProductImageCard: React.FC<ProductImageCardProps> = ({ imageUrl, className, imageSize }) => {
  return (
    <div
      className={` ${className ? className : ''} 
        shadow-card w-full h-auto pt-[100%] bg-center bg-no-repeat bg-cover p-6
      `}
      style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: imageSize ? imageSize : '60%' }}
    />
  )
}

export default ProductImageCard
