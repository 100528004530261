import React, { HtmlHTMLAttributes } from 'react'

interface TitleXxlProps extends HtmlHTMLAttributes<HTMLHeadingElement> {}

const TitleXxl: React.FC<TitleXxlProps> = ({ ...props }) => {
  return (
    <h6 {...props} className={`${props.className ? props.className : ''} font-medium text-xl xl:text-2xl`}>
      {props.children}
    </h6>
  )
}

export default TitleXxl
