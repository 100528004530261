import React from 'react'
import IconSquareBg from './IconSquareBg'

interface PhoneIconProps {}

const PhoneIcon: React.FC<PhoneIconProps> = () => {
  return (
    <IconSquareBg>
      <img src="/assets/icons/phone.svg" alt="Phone icon" />
    </IconSquareBg>
  )
}

export default PhoneIcon
