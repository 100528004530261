import { Button } from 'flowbite-react'
import React from 'react'
import { Link } from 'react-router-dom'

interface ButtonWithShadowProps {
  text: string
  onClick?(): void
  disabled?: boolean
  type?: 'submit' | 'reset' | 'button'
}

const ButtonWithShadow: React.FC<ButtonWithShadowProps> = ({ text, onClick, disabled, type }) => {
  return (
    <div className="relative inline-block">
      <Button
        color="primary"
        // className="z-10 px-6 hover:bg-opacity-100 hover:-translate-x-1 hover:translate-y-1"
        className="z-10 px-6 hover:bg-opacity-100"
        onClick={onClick}
        disabled={disabled}
        type={type}
      >
        {text}
      </Button>
      <div className="w-full h-full bg-warning-light absolute top-1 -left-1 -z-0"></div>
    </div>
  )
}

export default ButtonWithShadow
