import React, { useEffect, useState } from 'react'
import ProductImageCard from '../../components/molecules/Card/ProductImageCard'

import substationProducts from './constants/products.json'
import BackToTopIcon from '../../components/atoms/Icons/BackToTopIcon'
import TextBase from '../../components/atoms/Typography/TextBase'

const SubstationProducts = () => {
  const [showBackToTopButton, setShowBackToTopButton] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const triggerPoint = window.innerHeight * 3 // Scroll threshold (3 times screen height)
      setShowBackToTopButton(scrollPosition >= triggerPoint)
    }

    window.addEventListener('scroll', handleScroll)

    // Clean up listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div className="container mx-auto px-4 md:px-2 py-5">
      <TextBase fontMM>
        စက်ရုံအလုပ်ရုံရှိ ဓါတ်အား​ပေးခွဲရုံများတွင် မရှိမဖြစ်လိုအပ်သော substation equipments အကြောင်း သေချာနားလည်ပြီး
        ကိုယ်တိုင်အကျိုးရှိစွာ အသုံးပြုနိုင်စေရန် ကျွမ်းကျင်Engineers များမှ ဆွေးနွေးအကြံပေးခြင်း ၊ အသုံးပြုပုံနှင့်
        Do&Don’t များ လမ်းညွှန်းပေးခြင်း၊ After Sale Service နှင့် One Stop Solution ပြုလုပ်ပေးနိုင်ခြင်းတို့ကြောင့်
        ဓါတ်အား​ပေးခွဲရုံလုပ်ငန်းများအတွက် AME ၏ substation Productများက အ​ရွေးချယ်သင့်ဆုံးအဖြစ် အမြဲအသင့်ရှိ​နေပါပြီီ။
      </TextBase>

      <div className="grid grid-cols-12 gap-6 py-6">
        {substationProducts.map((each) => (
          <div className={`md:col-span-4 2xl:col-span-3 col-span-6`} key={each.id}>
            <ProductImageCard imageUrl={each.url} />
            <p className="text-center mt-3 text-sm md:text-base font-medium">{each.name}</p>
          </div>
        ))}
      </div>

      {showBackToTopButton && (
        <div className="fixed right-5 bottom-5 md:right-10 md:bottom-10">
          <BackToTopIcon className="cursor-pointer" onClick={handleScrollToTop} />
        </div>
      )}
    </div>
  )
}

export default SubstationProducts
