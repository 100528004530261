import React from 'react'

interface IconSquareBgProps {
  children: React.ReactNode
}

const IconSquareBg: React.FC<IconSquareBgProps> = ({ children }) => {
  return <div className="w-[40px] h-[40px] bg-warning-light flex justify-center items-center">{children}</div>
}

export default IconSquareBg
