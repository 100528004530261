import React from 'react'
import IconSquareBg from './IconSquareBg'

interface LocationIconProps {}

const LocationIcon: React.FC<LocationIconProps> = () => {
  return (
    <IconSquareBg>
      <img src="/assets/icons/location-icon.svg" alt="Location icon" />
    </IconSquareBg>
  )
}

export default LocationIcon
