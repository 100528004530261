import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TextBase from '../../components/atoms/Typography/TextBase'
import { TextInput, Textarea } from 'flowbite-react'
import ButtonWithShadow from '../../components/atoms/Buttons/ButtonWithShadow'
import { useForm } from 'react-hook-form'
import useBreakPoint from '../../hooks/useBreakPoint'

import emailjs from '@emailjs/browser'

import { Toast } from 'flowbite-react'
import { MdOutlineError } from 'react-icons/md'
import { MdEmail } from 'react-icons/md'

type FormValues = {
  name: string
  phone: string
  company?: string
  message: string
}

type ApiStatus = 'initial' | 'success' | 'fail'

const Contact: React.FC = () => {
  /** ------------------------------ States ------------------------------ */
  const [visibleToast, setVisibleToast] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<ApiStatus>('initial')

  /** ------------------------------ Hooks ------------------------------ */
  const { md } = useBreakPoint()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset: resetForm
  } = useForm<FormValues>({
    mode: 'onSubmit'
  })

  /** ------------------------------ Logics ------------------------------ */

  const sendEmail = async (values: FormValues) => {
    setLoading(true)

    emailjs
      .send(
        process.env.REACT_APP_APP_EMAILJS_SERVICE_ID as string,
        process.env.REACT_APP_APP_EMAILJS_TEMPLATE_ID as string,
        {
          from_name: values.name,
          to_name: process.env.REACT_APP_APP_NAME,
          phone_number: values.phone,
          company_name: values.company,
          message: values.message
        },
        {
          publicKey: process.env.REACT_APP_APP_EMAILJS_PUBLIC_KEY
        }
      )
      .then(
        async () => {
          handleAfterMailSend('success')
        },
        (error) => {
          handleAfterMailSend('fail')
          console.log('FAILED...', error.text)
        }
      )
  }

  const handleOnSubmit = (values: FormValues) => {
    sendEmail(values)
  }

  const handleAfterMailSend = (status: ApiStatus) => {
    setStatus(status)
    setLoading(false)
    setVisibleToast(true)

    resetForm()

    setTimeout(() => {
      setVisibleToast(false)
      setStatus('initial')
    }, 5000)
  }

  /** ------------------------------ Component UI Render ------------------------------ */
  return (
    <div
      className="container mx-auto px-4 md:px-2 py-5 mb-8"
      style={{ minHeight: md ? `${window.innerHeight - 430}px` : '350px' }}
    >
      <div className="grid grid-cols-12 lg:gap-24 gap-6">
        <div className="lg:col-span-6 col-span-12 lg:order-2">
          <TextBase fontMM>
            <b>09 - 977 120 528</b> သို့မဟုတ် <b>09 - 977 120 529</b> သို့ ဖုန်းခေါ်ဆိုပါ သို့မဟုတ် ဖောင်ဖြည့်ပြီး
            မကြာမီ သင့်ထံ ဆက်သွယ်ပေးပါမည်။
          </TextBase>

          <div className="contact-form-wrapper mt-5">
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <TextInput
                {...register('name', { required: 'Name is required', maxLength: 150 })}
                className={`${!errors.name ? 'mb-6' : ''}`}
                placeholder="Your Name"
                type="text"
                sizing="md"
                color={errors.name ? 'failure' : 'light'}
                required
              />
              {errors.name && <span className="text-red-500 text-[12px]">{errors.name.message}</span>}

              <TextInput
                {...register('phone', {
                  required: 'Phone number is required',
                  // eslint-disable-next-line no-useless-escape
                  pattern: { value: /^[0-9+\-]+$/, message: 'Input must only contain numbers, "+" or "-"' }
                })}
                className={`${!errors.phone ? 'mb-6' : ''}`}
                placeholder="Your Contact Number"
                color={errors.phone ? 'failure' : 'light'}
                type="text"
                sizing="md"
                required
              />
              {errors.phone && <span className="text-red-500 text-[12px]">{errors.phone.message}</span>}

              <TextInput
                {...register('company', { maxLength: 200 })}
                className="mb-6"
                placeholder="Your company (optional)"
                color={errors.company ? 'failure' : 'light'}
                type="text"
                sizing="md"
              />
              {errors.company && <span className="text-red-500 text-[12px]">{errors.company.message}</span>}

              <Textarea
                {...register('message', { required: 'Message is required', maxLength: 2500 })}
                color={errors.message ? 'failure' : 'light'}
                placeholder="Message"
                required
                rows={6}
              />
              {errors.message && <span className="text-red-500 text-[12px]">{errors.message.message}</span>}

              <div className="text-center mt-7">
                <ButtonWithShadow text="Send Message" type="submit" disabled={loading} />
              </div>
            </form>
          </div>
        </div>

        <div className="lg:col-start-2 lg:col-span-4 col-span-12 lg:order-1">
          <p className="mb-1 mt-2">
            46/38,140(B)St, Between 60B St x 60C St, Pyaygitagon Tsp, Mandalay. &nbsp;
            <Link to={process.env.REACT_APP_APP_BRAND_OFFICE_MAP_LINK || '#'} className="text-primary" target="_blank">
              maps
            </Link>
          </p>
          <img src="/assets/info-images/contact-image-1.jpg" alt="Company buliding 1" className="mb-3" />

          <p className="mb-1">
            Corner of 62 St & Pyay Lone Kyaw Ward. Near Swel Taw Highway Complex,Amarapura Tsp,Mandalay. &nbsp;
            <Link to={process.env.REACT_APP_APP_BRAND_FACTORY_MAP_LINK || '#'} className="text-primary" target="_blank">
              maps
            </Link>
          </p>
          <img src="/assets/info-images/contact-image-2.jpg" alt="Company buliding 1" />
        </div>
      </div>

      <Toast className={`top-10 right-10 shadow-card ${visibleToast ? 'fixed' : 'invisible'} transition-all`}>
        {status === 'success' && <MdEmail className="h-5 w-5 text-primary" />}
        {status === 'fail' && <MdOutlineError className="h-5 w-5 text-primary" />}

        <div className="pl-4 text-sm font-normal">Message sent successfully.</div>
        <Toast.Toggle onClick={() => setVisibleToast(false)} />
      </Toast>
    </div>
  )
}

export default Contact
