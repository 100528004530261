import React from 'react'
import TitleXxl from '../../../components/atoms/Typography/TitleXxl'
import TitleBase from '../../../components/atoms/Typography/TitleBase'
import { Button } from 'flowbite-react'
import { Link } from 'react-router-dom'
import { MenuPath } from '../../../constants/menus'

const OurProducts = () => {
  return (
    <section className="py-5 container mx-auto px-4 md:px-2">
      <div className="mb-5">
        <TitleXxl className="text-center mb-3">Our Products</TitleXxl>

        <TitleBase className="!font-normal text-center">
          ကျွန်တော်တို့၏ ကျယ်ပြန့်သော အရည်အသွေးမြင့် Substation ၊ Solar နှင့် IoT ထုတ်ကုန်များ
        </TitleBase>
      </div>

      <div className="grid grid-cols-12 gap-6">
        <div className="md:col-span-4 col-span-12">
          <img src="/assets/products/home_product_1_2x.jpg" alt="home product 1" />
          <Link to={MenuPath.SOLAR_PRODUCTS} className=" inline-block">
            <Button color="primary">Solar Products</Button>
          </Link>
        </div>

        <div className="md:col-span-4 col-span-12">
          <img src="/assets/products/home_product_2_2x.jpg" alt="home product 1" />
          <Link to={MenuPath.SUBSTATION_PRODUCTS} className=" inline-block">
            <Button color="primary">Substation Products</Button>
          </Link>
        </div>

        <div className="md:col-span-4 col-span-12">
          <img src="/assets/products/home_product_3_2x.jpg" alt="home product 1" />
          <Link to={MenuPath.IOT_PRODUCTS} className=" inline-block">
            <Button color="primary">IoT Products</Button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default OurProducts
