import { CustomFlowbiteTheme } from 'flowbite-react'

const customTheme: CustomFlowbiteTheme = {
  button: {
    color: {
      primary: 'bg-primary hover:bg-primary-600 hover:bg-opacity-70 text-warning-light !rounded-none transition-all'
    }
  },
  textInput: {
    field: {
      input: {
        base: '!rounded-sm !px-3 w-full text-sm md:text-base placeholder-mute focus:ring-transparent',
        colors: {
          light:
            'border-border-color  bg-white  focus:border-primary focus:ring-transparent dark:border-primary dark:bg-primary dark:focus:border-primary dark:focus:ring-primary',

          failure:
            'border-red-500 bg-white focus:border-red-500 focus:ring-transparent dark:border-primary dark:bg-primary dark:focus:border-red-500 dark:focus:ring-primary'
        }
      }
    }
  },
  textarea: {
    base: 'block w-full !rounded-sm !px-3 border-border-color disabled:cursor-not-allowed disabled:opacity-50 text-sm md:text-base',
    colors: {
      light:
        'border-border-color  bg-white  focus:border-primary focus:ring-transparent dark:border-primary dark:bg-primary dark:focus:border-primary dark:focus:ring-primary',

      failure:
        'border-red-500 bg-white focus:border-red-500 focus:ring-transparent dark:border-primary dark:bg-primary dark:focus:border-red-500 dark:focus:ring-primary'
    }
  }
}

export default customTheme
