import React from 'react'
import Logo from '../../../atoms/Logo'
import { Menu, MenuLabel, MenuPath } from '../../../../constants/menus'
import MenuItem from '../Header/components/MenuItem'
import FacebookIcon from '../../../atoms/Icons/FacebookIcon'
import EmailIcon from '../../../atoms/Icons/EmailIcon'
import LocationInfo from '../../../molecules/InfoItems/LocationInfo'
import WorkingHoursInfo from '../../../molecules/InfoItems/WorkingHoursInfo'
import PhoneNumberInfo from '../../../molecules/InfoItems/PhoneNumberInfo'
import useBreakPoint from '../../../../hooks/useBreakPoint'
import { Link } from 'react-router-dom'

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const { xs } = useBreakPoint()

  return (
    <div
      className="pt-8 pb-5 bg-center bg-no-repeat bg-cover"
      style={{
        backgroundImage: xs
          ? "url('assets/background-images/footer_bg.jpg')"
          : "url('assets/background-images/footer_bg_mobile_2x.jpg')"
      }}
    >
      <div className="container mx-auto px-4 md:px-2">
        <div className="flex flex-col items-start md:flex-row md:justify-between md:items-center  gap-5 md:gap-6">
          <div className="flex items-center">
            <Link to={MenuPath.HOME}>
              <Logo />
            </Link>

            {/* Social Links Mobile */}
            <div className="flex gap-5 md:hidden ml-6">
              <Link to={process.env.REACT_APP_APP_BRAND_FACEBOOK_LINK || '#'} target="_blank">
                <FacebookIcon />
              </Link>

              <Link to={`mailto:${process.env.REACT_APP_APP_BRAND_EMAIl}`}>
                <EmailIcon />
              </Link>
            </div>
          </div>

          <div className="flex flex-col md:flex-row  justify-between gap-3 md:gap-0 md:px-10 flex-1">
            {Object.values(Menu)
              .filter((each) => each !== Menu.Home)
              .map((each) => (
                <MenuItem name={MenuLabel[each]} path={MenuPath[each]} key={each} footer />
              ))}
          </div>
        </div>

        <div className="flex gap-5 md:gap-20 md:pl-4 mt-8 md:mt-3">
          {/* Social Links Desktop */}
          <div className="md:flex gap-5 hidden">
            <Link to={process.env.REACT_APP_APP_BRAND_FACEBOOK_LINK || '#'} target="_blank">
              <FacebookIcon />
            </Link>

            <Link to={`mailto:${process.env.REACT_APP_APP_BRAND_EMAIl}`}>
              <EmailIcon />
            </Link>
          </div>

          {/* Brand Info Banner */}
          <div className="flex flex-col md:flex-row md:items-center gap-8 md:gap-5">
            <LocationInfo className="text-white" />

            <WorkingHoursInfo className="text-white" />

            <PhoneNumberInfo className="text-white" />
          </div>
        </div>

        <div className="flex justify-center mt-14">
          <p className="text-sm md:text-base font-thin text-white ">Copyright © 2024 AME Ltd . All rights reserved.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
